import { useMutation, useQuery } from "@tanstack/react-query";
import { GetCommanWay, ListCommanWay } from "API/commonApi";
import { Img } from "components";
import { debounce } from "lodash";
import React, { useCallback, useEffect, useState } from "react";

import "react-circular-progressbar/dist/styles.css";
import { Link, useLocation, useParams } from "react-router-dom";

const Footer = () => {
  const BASE_URL = process.env.REACT_APP_BACKEND_URL;
  const [categories, setCategories] = useState([]);
  let [lang, setLang] = useState<string>(localStorage.getItem("newsaudible_lang") || "HINDI");

  const {} = useQuery(
    ["trendingNewsListAPI"],
    () =>
      ListCommanWay(
        "/admin/news/list",
        { cat: "trending", lang: lang },
        { include: ["_mainCategory"], paginate: 5, sort: { views: -1 }, group: ["mainCategory"] }
      ),
    {
      keepPreviousData: true,
      onSuccess(data) {
        //console.log(data.data.data);
        if (data.status == "SUCCESS") {
          setCategories(data.data.data);
        }
      },
    }
  );

  return (
    <div className="font-sans-serif">
      <div className="relative px-20 py-8 sm:px-4 bg-[#FFFCF1]">
        <div className="flex sm:flex-col items-center justify-between sm:text-center">
          <div>
            <p className="font-bold text-xl">We are also available at playstore</p>
            <p className="text-primary mt-3">Download our app today</p>
          </div>
          <a href="https://play.google.com/store/apps/details?id=com.softtech.newsaudible" target="_blank">
            <Img src="/play_store.png" className="h-14 sm:mt-5" />
          </a>
        </div>
      </div>

      <div className="relative px-20 py-6 sm:px-4 bg-primary text-white">
        <div className="flex sm:block items-start justify-between">
          <div className="text-center self-center mb-3">
            <Img src="/logo192.png" className="h-14 inline-block" />
            <br />
            <Img src="/logo.png" className="mt-5 h-8 inline-block" />
          </div>

          <div className="leading-10 sm:mt-5">
            <h3 className="font-bold text-xl">Recently updated</h3>
            {categories.map((c) => {
              return (
                <Link to={"/category/" + c._mainCategory?.slug} key={"footer" + c.id}>
                  <p>{c._mainCategory.name}</p>{" "}
                </Link>
              );
            })}
          </div>

          <div className="leading-10 sm:mt-10 flex flex-col">
            <h3 className="font-bold text-xl">Quick links</h3>
            <Link to={"/home"}>Home</Link>
            <Link to={"/about"}>About us</Link>
            <Link to={"/contact"}>Contact us</Link>
            <Link to={"/privacy-policy"}>Privacy policy</Link>
            <span>Terms & Conditions</span>
          </div>

          <div className="leading-8 sm:mt-10">
            <h3 className="font-bold text-xl">Follow us</h3>
            <div className="flex flex-col">
              <a href="https://whatsapp.com/channel/0029VaA6vuc8aKvB3e49GJ2N" target="_blank">
                Whatsapp
              </a>
              <a href="https://www.instagram.com/newsaudible?igsh=cXRiZTdvbzBmdmc3" target="_blank">
                Instagram
              </a>
              <a href="https://x.com/newsaudibleME?t=L6LmtTpUC5TAPDhElDa_RQ&s=09" target="_blank">
                X
              </a>
              <a href="https://www.facebook.com/profile.php?id=61555961889974&mibextid=ZbWKwL" target="_blank">
                Facebook
              </a>
              <a href="https://www.threads.net/@newsaudible" target="_blank">
                Threads
              </a>
              <a href="https://www.linkedin.com/in/newsaudible-media-486a4b243" target="_blank">
                LinkedIn
              </a>
            </div>
          </div>
        </div>

        <div className="text-[#958C7D] text-sm text-center border-t border-[#847968] pt-6">
          All rights and copyrights reserves to Newsaudible®
        </div>
      </div>
    </div>
  );
};
export default Footer;
