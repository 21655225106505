import React, { useEffect } from "react";
import AppContent from "../components/AppContent/AppContent";
import Navbar from "components/Navbar/Navbar";
import Footer from "components/Footer";
import { useLocation } from "react-router-dom";

export function DefaultLayout() {
  const { pathname } = useLocation();
  // Automatically scrolls to top whenever pathname changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    const handleContextmenu = (e) => {
      e.preventDefault();
    };
    document.addEventListener("contextmenu", handleContextmenu);
    return function cleanup() {
      document.removeEventListener("contextmenu", handleContextmenu);
    };
  }, []);

  return (
    <>
      <section>
        <div className="relative px-20 pt-5 md:px-4">
          <Navbar />

          <div className="">
            {/* <div className="bg-background_grey h-screen"> */}
            <div className="">
              <AppContent />
            </div>
          </div>
        </div>
        <Footer />
      </section>
    </>
  );
}
