/* eslint-disable react-hooks/exhaustive-deps */
import React, { Suspense } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import PrivateRouter, { PrivateRouteProps } from "./PrivateRouter";
import PublicRouter, { PublicRouteProps } from "./PublicRouter";
import Spinner from "../../utils/Spinner";
import { DefaultLayout } from "../../LayoutPage/MainLayout";
// import { ForgotPassword }  from "../../Layout/ForgotPassword";
// import { ResetPassword }  from "../../Layout/ResetPassword";
import { Login } from "../../pages/Auth";
import { ForgotPass } from "pages/Auth/ForgotPass";
import { SignUp } from "pages/Auth/SignUp";
import { ResetPass } from "pages/Auth/ResetPass";

const RootNavigator = () => {
  return (
    <Suspense
      fallback={
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <Spinner />
        </div>
      }
    >
      {/* <Router> */}
      <Routes>
        <Route path="/login" element={<PublicRouter {...defaultPublicRouteProps} outlet={<Login />} />} />
        <Route path="/signup" element={<PublicRouter {...defaultPublicRouteProps} outlet={<SignUp />} />} />
        <Route path="/forgot-password" element={<PublicRouter {...defaultPublicRouteProps} outlet={<ForgotPass />} />} />
        <Route path="/reset-password" element={<PublicRouter {...defaultPublicRouteProps} outlet={<ResetPass />} />} />

        <Route path="*" element={<PublicRouter {...defaultPublicRouteProps} outlet={<DefaultLayout />} />} />

        {/*<Route
          path="*"
          element={
            <PrivateRouter
              {...defaultPrivateRouteProps}
              outlet={<DefaultLayout />}
            />
          }
        />*/}
      </Routes>
      {/* </Router> */}
    </Suspense>
  );
};

export default RootNavigator;

const defaultPublicRouteProps: Omit<PublicRouteProps, "outlet"> = {
  authenticatedPath: "/",
};

const defaultPrivateRouteProps: Omit<PrivateRouteProps, "outlet"> = {
  authenticationPath: "/login",
};
