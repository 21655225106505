import React, { lazy, memo, Suspense } from "react";
import { Navigate, Route, Router, Routes } from "react-router-dom";
import { lazyImport } from "../../utils/lazyImport";

import PrivateRouter, { PrivateRouteProps } from "../../Routes/RootNavigator/PrivateRouter";
import Spinner from "../../utils/Spinner";
import PublicRouter, { PublicRouteProps } from "../../Routes/RootNavigator/PublicRouter";
import Home from "pages/Home";
import SingleNews from "pages/SingleNews";
import AboutUs from "pages/AboutUs";
import ContactUs from "pages/ContactUs";
import Season from "pages/Season";
import { MyAccount } from "pages/Auth/MyAccount";
import { ChangePass } from "pages/Auth/ChangePass";
import PrivacyPolicy from "pages/PrivacyPolicy";

const { NotFound } = lazyImport(() => import("pages/NotFound"), "NotFound");

const { Dashboard } = lazyImport(() => import("pages/Dashboard"), "Dashboard");

const AppContent = () => {
  return (
    <Suspense
      fallback={
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <Spinner />
        </div>
      }
    >
      <Routes>
        <Route path="/" element={<Home props={{ page: "home" }} />} />
        <Route path="/category/:cat" element={<Home props={{ page: "cat" }} />} />
        <Route path="/category/:cat/:subcat" element={<Home props={{ page: "subCat" }} />} />

        <Route path="/news/:id" element={<SingleNews />} />
        <Route path="/season/:id" element={<Season />} />

        <Route path="/about" element={<AboutUs />} />
        <Route path="/contact" element={<ContactUs />} />

        <Route path="/my-account" element={<MyAccount />} />
        <Route path="/change-password" element={<ChangePass />} />

        <Route path="/privacy-policy" element={<PrivacyPolicy />} />

        <Route path="/dashboard">
          {/*<Route index element={<PrivateRouter {...defaultPrivateRouteProps} outlet={<Dashboard />} />} />*/}
          <Route index element={<Dashboard />} />
        </Route>
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Suspense>
  );
};

export default memo(AppContent);

const defaultPublicRouteProps: Omit<PublicRouteProps, "outlet"> = {
  authenticatedPath: "/",
};

const defaultPrivateRouteProps: Omit<PrivateRouteProps, "outlet"> = {
  authenticationPath: "/login",
};
