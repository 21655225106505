import React, { useState } from "react";

import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

import { PostLoginResponseType, postForgot, postLogin } from "service/api";
import * as yup from "yup";

import { Button, Img, Input, Text } from "components";

import useForm from "hooks/useForm";

import "react-toastify/dist/ReactToastify.css";
import { useAuthStore } from "store/auth";
import UseToast from "utils/AfToast";
import Spinner from "utils/Spinner";

export const ForgotPass: React.FC = () => {
  const { setToken, setUser, token } = useAuthStore();
  const [login, setLogin] = React.useState<PostLoginResponseType>();
  const formValidationSchema = yup.object().shape({
    email: yup.string().required("Email is required"),
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [passVisible, setPassVisible] = React.useState<boolean>(false);

  const form = useForm(
    { email: "" },
    {
      validate: true,
      validateSchema: formValidationSchema,
      validationOnChange: true,
    }
  );
  const navigate = useNavigate();

  function login1(data: any) {
    setIsLoading(true);
    postForgot({ data: data })
      .then((res) => {
        setIsLoading(false);
        console.log(res);
        if(res.data.status){
          UseToast("Reset Password OTP successfully send to your email.","success");
          navigate("/reset-password");
        }
        //navigate("/dashboard");
      })
      .catch((err) => {
        console.error(err);
        setIsLoading(false);
        UseToast("Reset Password Failed! Try Again", "error");
      });
  }

  return (
    <>
    {isLoading && <Spinner />}
      <div className="bg-gray-50 flex flex-col font-gilroy items-center justify-center mx-auto md:px-10 sm:px-5 w-full h-full">
        <div className="flex flex-col gap-[30px] items-center justify-start max-w-xl">
          <div className=" bg-[#FFFBF5] border border-[#EFBD22] py-10 md:5 px-20 md:px-10 sm:px-4 rounded-lg relative">
          <div className="text-right">
              <Button
                onClick={() => {
                  navigate("/");
                }}
                className="bg-primary text-sm text-white rounded-md px-2 py-1 absolute top-2 right-2"
              >
                Back to Home
              </Button>
            </div>
            <div className="flex flex-col items-center justify-start w-full ">
              <div className="flex items-center gap-4">
                <Img src="/logo192.png" className="h-10" />
                <Img src={"/logo.png"} style={{ mixBlendMode: "difference" }} width={150} />
              </div>

              <div className="text-center mt-6">
                <h1 className="font-bold text-primary text-xl">Forgot password</h1>
                <p className="text-[#7A7A7A] mt-2 mb-4">Enter your registered email below to continue</p>
              </div>

              <div className="w-full mt-6">
                <Input
                  name="email"
                  placeholder="Enter your Email*"
                  className="font-medium p-0 text-base text-left w-full"
                  wrapClassName="bg-white border border-blue_gray-100 border-solid p-3 rounded-lg w-full"
                  type="text"
                  onChange={(e) => {
                    form.handleChange("email", e);
                  }}
                  errors={form?.errors?.email}
                  value={form?.values?.email}
                ></Input>
              </div>

              <div className="flex flex-col gap-4 items-center justify-start w-full my-10">
                <Button
                  className="common-pointer bg-primary text-white cursor-pointer font-medium py-[15px] rounded-md text-base text-center w-full"
                  onClick={() => {
                    form.handleSubmit(login1);
                  }}
                >
                  Submit
                </Button>
              </div>

              <div className="border-t border-[#A3A3A3] pt-8">
                <span className="text-sm">
                  Already have an account?
                  <Link to={"/login"}>
                    <span className="underline text-[#3B37FF]">Login</span>
                  </Link>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
