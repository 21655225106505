import { Button, Img, Input } from "components";
import React, { useCallback, useEffect, useState } from "react";
import "react-circular-progressbar/dist/styles.css";
import { Link } from "react-router-dom";

const PrivacyPolicy = () => {
  const BASE_URL = process.env.REACT_APP_BACKEND_URL;
  return (
    <div className="my-10 pr-2 pl-6">
      <h1 className="text-4xl text-primary font-medium">Privacy Policy</h1>

      <div className="my-10">
        <ul className="list-disc text-base font-medium text-black-900">
          <li>
            At Newsaudible®, we are committed to protecting your privacy and ensuring the security of your personal information. This
            Privacy Policy outlines how we collect, use, and safeguard the information you provide while registering with our app and/or
            website.
          </li>
        </ul>

        <ol className="list-decimal">
          <li className="text-2xl font-bold text-primary mt-7">
            Information Collection and Use:
            <ul className="list-disc text-base font-medium text-black-900 mt-4">
              <li>
                Newsaudible® collects minimal personal information from users, such as name date of birth, name of your city and email
                address, etc. solely for the purpose of registration and to enhance user experience.
              </li>
              <li>
                Any information collected from you or any other user of Newsaudible® app and / or website is used strictly for internal
                purposes and is never shared with third parties under any circumstances.
              </li>
            </ul>
          </li>

          <li className="text-2xl font-bold text-primary mt-7">
            Data Security
            <ul className="list-disc text-base font-medium text-black-900 mt-4">
              <li>
                We employ industry-standard security measures to protect the personal information submitted to us, both during transmission
                and once we receive it.
              </li>
              <li>
                Your personal information is stored securely and access is restricted to authorized personnel of Newsaudible® Media
                Enterprise only.
              </li>
            </ul>
          </li>

          <li className="text-2xl font-bold text-primary mt-7">
            Compliance with Government Guidelines:
            <ul className="list-disc text-base font-medium text-black-900 mt-4">
              <li>
                Newsaudible® adheres to all applicable laws and regulations, including the Government of India guidelines, regarding the
                privacy and protection of user information / data.
              </li>
              <li>
                We are committed to maintaining transparency and ensuring compliance with all relevant privacy laws and regulations across
                the globe.
              </li>
            </ul>
          </li>

          <li className="text-2xl font-bold text-primary mt-7">
            User Control and Opt-Out:
            <ul className="list-disc text-base font-medium text-black-900 mt-4">
              <li>
                Users can request us to delete their personal information at any time by contacting us on: request@newsaudible.in And / or
                by the process suggested here: <a href="https://support.newsaudible.in/request">https://support.newsaudible.in/request</a>
              </li>
            </ul>
          </li>

          <li className="text-2xl font-bold text-primary mt-7">
            Changes to Privacy Policy:
            <ul className="list-disc text-base font-medium text-black-900 mt-4">
              <li>
                We reserve the right to update or modify this Privacy Policy at any time. Any changes will be effective immediately upon
                posting the revised Privacy Policy on our app and the website.
              </li>
              <li>
                We encourage users to review this Privacy Policy periodically to stay informed about how we are protecting their
                information.
              </li>
            </ul>
          </li>
        </ol>

        <ul className="list-disc text-base font-medium text-black-900 mt-10">
          <li>
            By using Newsaudible® app and / or website, you consent to the terms of this Privacy Policy. If you have any questions or
            concerns about our Privacy Policy or practices, please contact us at request@newsaudible.in
          </li>
          <li>Last updated: 8th of April 2024</li>
          <li>Newsaudible® Media Enterprise Team</li>
        </ul>
      </div>
    </div>
  );
};
export default PrivacyPolicy;
