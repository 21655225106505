import React from "react";
import { Oval } from "react-loader-spinner";
/*import { CSSProperties } from "react";

const override: CSSProperties = {
  position: "fixed",
  top: "50%",
  left: "50%",
  zIndex: "2000",
};

let a = document.getElementsByClassName("body .sweet-loading");*/

function MiniSpinner() {
  return (
    <>
      <div
        className="sweet-loading bg-[#3f2e13] bg-opacity-50 absolute inset-0 flex justify-center items-center isolate z-50"
        
      >
        <Oval
          visible={true}
          height="80"
          width="80"
          color="#3f2e13"
          secondaryColor="#efbd22"
          ariaLabel="oval-loading"
          wrapperStyle={{}}
          wrapperClass=""
        />
      </div>
    </>
  );
}

export default MiniSpinner;
