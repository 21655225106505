import { Fragment, useState, useRef } from "react";
import * as React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { roles } from "utils/constant";
import { Img } from "components/Img";
import { useQuery } from "@tanstack/react-query";
import { GetCommanWay, ListCommanWay } from "API/commonApi";
import { ControlledMenu, Menu, MenuButton, MenuItem, SubMenu, useHover, useMenuState } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/transitions/slide.css";
import { Button } from "components/Button";
import { postLogout, postSignup } from "service/api";
import UseToast from "utils/AfToast";
import { useAuthStore } from "store/auth";
import { isMobile } from "react-device-detect";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

export default function Navbar() {
  const params = useParams();
  const location: any = useLocation();
  const id: string | undefined = params?.id;
  let pathName: any[] = location?.pathname?.split("/");
  const navigate = useNavigate();
  const { setToken, setUser, token, removeAll } = useAuthStore();

  const logout = () => {
    postLogout({ data: {}, headers: { Authorization: "Bearer " + token } })
      .then((res) => {
        UseToast("Logout Successfull", "success");
        localStorage.removeItem("newsaudible_token");
        removeAll();
        navigate("/login");
      })
      .catch((err) => {
        console.error(err);
        UseToast("Login Failed! Try Again", "error");
      });
  };

  const navigation = [
    { name: "Home", to: "/news", isActive: pathName[1] == "" || pathName[1] == "category" },
    { name: "About us", to: "/about", isActive: pathName[1] == "about" },
    { name: "Contact Us", to: "/contact", isActive: pathName[1] == "contact" },
  ];

  const [bigMenuOpen, setBigMenuOpen] = useState<boolean>(false);

  const [selectedCat, setSelectedCat] = useState("trending");

  const [sidebarOpen, setSidebarOpen] = useState(true);
  const mainCat = pathName.length > 2 ? pathName[2] : "trending";
  const subCat = pathName.length > 3 ? pathName[3] : "";

  const [categories, setCategories] = useState([]);

  //const ref = useRef(null);
  const refLang = useRef(null);
  const [isOpenLang, setOpenLang] = useState(false);
  const { anchorProps: anchorPropsLang, hoverProps: hoverPropsLang } = useHover(isOpenLang, setOpenLang);

  const [isOpen, setOpen] = useState(false);
  const { anchorProps, hoverProps } = useHover(isOpen, setOpen);

  let [mainCats, setMainCats] = useState<any[]>([]);

  let [lang, setLang] = useState<string>(localStorage.getItem("newsaudible_lang") || "HINDI");

  const [name, setName] = useState<string>();
  const {} = useQuery(["selfProfile"], () => GetCommanWay("/admin/user/me", {}, {}), {
    keepPreviousData: true,
    onSuccess(data) {
      console.log(data);
      if (data.status == "SUCCESS") {
        setName(data.data.username);
      }
    },
    onError(err) {},
  });

  useQuery(
    ["mainCategotyListAPI"],
    () =>
      ListCommanWay(
        "/admin/category/list",
        {
          lang: lang,
        },
        { paginate: 10000 }
      ),
    {
      keepPreviousData: true,
      onSuccess(data) {
        //console.log(data.data.data);
        if (data.status == "SUCCESS") {
          let _mainCat = [];
          data.data.data.forEach((f) => {
            _mainCat.push({ ...f, innerCats: [] });
          });
          let mainCatAry = [..._mainCat];
          if (mainCatAry.length > 40) {
            let ary1 = mainCatAry.slice(0, 40);
            let ary2 = mainCatAry.slice(40);
            mainCatAry = ary1;
            mainCatAry.push({ name: "More", id: -1, slug: "more", innerCats: ary2 });
          }
          setMainCats(mainCatAry);
        }
      },
    }
  );

  React.useEffect(() => {
    if (mainCats.length > 0) {
      subCatRefetch();
    }
  }, [mainCats]);

  const { refetch: subCatRefetch } = useQuery(
    ["categotyListAPI"],
    () =>
      ListCommanWay(
        "/admin/subcategory/list",
        {},
        {
          paginate: 10000,
          include: ["_category"],
        }
      ),
    {
      keepPreviousData: true,
      enabled: false,
      onSuccess(data) {
        //console.log(data.data.data);
        if (data.status == "SUCCESS") {
          let _mainCats: any[] = JSON.parse(JSON.stringify(mainCats));
          data.data.data.forEach((f) => {
            for (let i = 0; i < _mainCats.length; i++) {
              if (_mainCats[i].id == f.category) {
                _mainCats[i].innerCats.push(f);
                break;
              }
            }
          });
          //console.log("FINAL: _mainCats", _mainCats);
          setCategories(_mainCats);
        }
      },
    }
  );

  const sideNavigation = navigation.map((item) => {
    return (
      <NavLink
        to={item?.to}
        onClick={() => setSidebarOpen(false)}
        key={"navlink" + item.name}
        className={(props) => {
          return classNames(
            item.isActive ? "border-black-900" : "border-transparent text-[#B1B1B1]",
            "border-b  whitespace-nowrap flex items-center font-medium "
          );
        }}
      >
        {item.name}
      </NavLink>
    );
  });

  const sideNavigationMobile = navigation.map((item, i) => {
    return (
      <MenuItem className={"py-2 px-6 font-medium group"} key={"mobilemenu" + i}>
        <NavLink
          to={item?.to}
          onClick={() => setSidebarOpen(false)}
          className={(props) => {
            return classNames(
              item.isActive ? "border-black-900" : "border-transparent text-[#B1B1B1]",
              "border-b  whitespace-nowrap flex items-center font-medium "
            );
          }}
        >
          {item.name}
        </NavLink>
      </MenuItem>
    );
  });

  const HoverMenu = ({ item }) => {
    const ref = useRef(null);
    const [isOpen, setOpen] = useState(false);
    const { anchorProps, hoverProps } = useHover(isOpen, setOpen);
    const [isActive, setIsActive] = useState(item.slug == mainCat);

    return (
      <>
        <div
          ref={ref}
          {...anchorProps}
          className={`cursor-pointer py-3 relative font-medium after:absolute after:w-full after:h-1 after:rounded-t-full after:-bottom-[1px] after:left-0 ${
            isOpen || isActive ? "after:bg-secondary bg-li" : ""
          }`}
        >
          <Link to={`/category/${item.slug}`} className="whitespace-nowrap">
            {item.name}
          </Link>
          {isMobile && item.innerCats?.length > 0 && (
            <div className="border border-primary rounded ml-1 px-1.5 inline leading-3 pb-1">⏷</div>
          )}
        </div>

        <ControlledMenu
          className={""}
          menuClassName={`shadow-submenu py-1 translate-y-[49px] bg-[#fffcf1] border border-primary rounded-md z-20 ${
            item.innerCats?.length == 0 ? "hidden" : ""
          }`}
          {...hoverProps}
          state={isOpen ? "open" : "closed"}
          anchorRef={ref}
          onClose={() => setOpen(false)}
        >
          {item.innerCats &&
            item.innerCats.map((subItem) => {
              return (
                <MenuItem key={"nest2" + subItem.id} className={`py-2 px-6 font-medium group z-20`}>
                  <Link to={`/category/${item.slug}/${subItem.slug}`}>
                    <span className="group-hover:border-b border-black-900 text-primary">{subItem.name}</span>
                  </Link>
                </MenuItem>
              );
            })}
        </ControlledMenu>
      </>
    );
  };

  const onLangChange = (v) => {
    localStorage.setItem("newsaudible_lang", v);
    window.location.replace("/");
  };

  const getLanguageMenu = () => {
    return (
      <div>
        <div
          ref={refLang}
          {...anchorPropsLang}
          className={`cursor-pointer py-3 relative font-medium after:absolute after:w-full after:h-1 after:rounded-t-full after:-bottom-[1px] after:left-0`}
        >
          {localStorage.getItem("newsaudible_lang") ? localStorage.getItem("newsaudible_lang") : "HINDI"}
        </div>

        <ControlledMenu
          className={""}
          menuClassName={`shadow-submenu py-1 z-10 bg-[#FFFCF1] border border-primary rounded`}
          {...hoverPropsLang}
          state={isOpenLang ? "open" : "closed"}
          anchorRef={refLang}
          onClose={() => setOpenLang(false)}
        >
          <MenuItem
            className={`py-2 px-6 font-medium group z-20`}
            onClick={() => {
              onLangChange("ENGLISH");
            }}
          >
            <span className="group-hover:border-b border-black-900">ENGLISH</span>
          </MenuItem>
          <MenuItem
            className={`py-2 px-6 font-medium group`}
            onClick={() => {
              onLangChange("HINDI");
            }}
          >
            <span className="group-hover:border-b border-black-900">HINDI</span>
          </MenuItem>
          <MenuItem
            className={`py-2 px-6 font-medium group`}
            onClick={() => {
              onLangChange("GUJARATI");
            }}
          >
            <span className="group-hover:border-b border-black-900">GUJARATI</span>
          </MenuItem>
        </ControlledMenu>
      </div>
    );
  };

  return (
    <div key={"Asd"}>
      {bigMenuOpen && (
        <div className="fixed bg-white left-0 top-0 w-screen h-screen z-10 overflow-auto py-6">
          <div className="flex justify-end px-6 mb-2">
            <Button
              onClick={() => {
                setBigMenuOpen(!bigMenuOpen);
              }}
              className={"flex items-center p-2.5 bg-secondary rounded-full text-white"}
            >
              <svg xmlns="http://www.w3.org/2000/svg" width={22} height={22} fill="none" viewBox="0 0 24 24">
                <path
                  fill="#0F0F0F"
                  d="M6.995 7.006a1 1 0 0 0 0 1.415l3.585 3.585-3.585 3.585a1 1 0 1 0 1.414 1.414l3.585-3.585 3.585 3.585a1 1 0 0 0 1.415-1.414l-3.586-3.585 3.586-3.585a1 1 0 0 0-1.415-1.415l-3.585 3.585L8.41 7.006a1 1 0 0 0-1.414 0Z"
                />
              </svg>
            </Button>
          </div>

          <div className="sm:columns-2 md:columns-3 columns-4 2xl:columns-4 px-6 gap-6 [column-fill:_balance] box-border before:box-inherit after:box-inherit">
            <div className="break-inside-avoid mb-10 rounded-lg">
              <Link
                onClick={() => {
                  setBigMenuOpen(false);
                }}
                to={`/category/trending`}
              >
                <p className="font-semibold uppercase mb-2">Home</p>
              </Link>
            </div>

            {categories.map((item: any) => {
              return (
                <div className="break-inside-avoid mb-10 rounded-lg">
                  <p className="font-semibold uppercase mb-2">{item.name}</p>
                  {item.innerCats?.map((inn: any) => {
                    return (
                      <Link
                        onClick={() => {
                          setBigMenuOpen(false);
                        }}
                        to={`/category/${item.slug}/${inn.slug}`}
                      >
                        <p className="ml-3 flex items-center gap-1">
                          <svg xmlns="http://www.w3.org/2000/svg" width={15} height={15} fill="none" viewBox="0 0 24 24">
                            <path stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="m10 7 5 5-5 5" />
                          </svg>
                          {inn.name}
                        </p>
                      </Link>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </div>
      )}

      {/* responsive */}
      <div className="">
        <div className="flex bg-white justify-between pt-4 pb-5">
          <div className="flex flex-shrink-0 items-center px-4">
            <Link to="/">
              <Img src={"/logo.png"} style={{ mixBlendMode: "difference" }} width={200} />
            </Link>
          </div>

          <nav className="flex gap-6 md:gap-4 items-center bg-white px-2 overflow-y-visible" aria-label="Sidebar">
            <div className="md:hidden flex gap-6 md:gap-4 items-center bg-white px-2 overflow-y-visible">
              {sideNavigation}
              <div key={"main-login-reg"}>
                <div>
                  {!name ? (
                    <NavLink
                      to="/login"
                      onClick={() => setSidebarOpen(false)}
                      className={(props) => {
                        return classNames(
                          "whitespace-nowrap flex items-center font-medium pb-3 pt-2 px-8 bg-primary rounded-full text-white"
                        );
                      }}
                    >
                      Login
                    </NavLink>
                  ) : (
                    <>
                      <Link to={"/my-account"}>
                        <Button
                          className={"whitespace-nowrap flex items-center font-medium pb-3 pt-2 px-8 bg-primary rounded-full text-white"}
                        >
                          My Account
                        </Button>
                      </Link>
                    </>
                  )}
                </div>
              </div>

              {getLanguageMenu()}

              <div>
                <Button
                  onClick={() => {
                    setBigMenuOpen(!bigMenuOpen);
                  }}
                  className={"flex items-center p-2.5 bg-secondary rounded-full text-white"}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width={22} height={22} fill="none" viewBox="0 0 24 24">
                    <path stroke="#000" strokeLinecap="round" strokeWidth={2} d="M4 18h16M4 12h16M4 6h16" />
                  </svg>
                </Button>
              </div>
            </div>

            <div className="hidden md:flex  gap-6 md:gap-4 items-center bg-white px-2 overflow-y-visible">
              <Menu
                className={"bg-white"}
                menuClassName={`shadow-submenu py-1 bg-white z-10 translate-y-7 translate-x-7`}
                direction="left"
                menuButton={
                  <MenuButton>
                    <svg xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" width={24} height={24} viewBox="0 0 32 32">
                      <path d="M13 16c0 1.654 1.346 3 3 3s3-1.346 3-3-1.346-3-3-3-3 1.346-3 3zM13 26c0 1.654 1.346 3 3 3s3-1.346 3-3-1.346-3-3-3-3 1.346-3 3zM13 6c0 1.654 1.346 3 3 3s3-1.346 3-3-1.346-3-3-3-3 1.346-3 3z" />
                    </svg>
                  </MenuButton>
                }
                transition
              >
                {sideNavigationMobile}
                <div className="px-6 text-[#B1B1B1]">{getLanguageMenu()}</div>
                <MenuItem className={"py-2 px-6 font-medium group"} key={"mobilemenu"}>
                  {!name ? (
                    <NavLink
                      to="/login"
                      onClick={() => setSidebarOpen(false)}
                      className={(props) => {
                        return classNames(
                          "whitespace-nowrap flex items-center font-medium pb-3 pt-2 px-8 bg-primary rounded-full text-white"
                        );
                      }}
                    >
                      Login
                    </NavLink>
                  ) : (
                    <>
                      <Link to={"/my-account"}>
                        <Button
                          className={"whitespace-nowrap flex items-center font-medium pb-3 pt-2 px-8 bg-primary rounded-full text-white"}
                        >
                          My Account
                        </Button>
                      </Link>
                    </>
                  )}
                </MenuItem>
              </Menu>

              <div>
                <Button
                  onClick={() => {
                    setBigMenuOpen(!bigMenuOpen);
                  }}
                  className={"flex items-center p-2.5 bg-secondary rounded-full text-white"}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width={22} height={22} fill="none" viewBox="0 0 24 24">
                    <path stroke="#000" strokeLinecap="round" strokeWidth={2} d="M4 18h16M4 12h16M4 6h16" />
                  </svg>
                </Button>
              </div>
            </div>
          </nav>
        </div>

        <div className="border-y box-border border-[#C6C6C6]"></div>

        {/*<div className="flex gap-6 justify-between border-y box-border border-[#C6C6C6] overflow-x-auto overflow-y-hidden">
          {categories.map((item: any) => {
            return <HoverMenu item={item} key={"hovermenu" + item?.slug} />;
          })}
        </div>*/}
      </div>
    </div>
  );
}
