import { useMutation } from "@tanstack/react-query";
import { Button, Img, Input } from "components";
import { request } from "config/request";
import { useFormik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import "react-circular-progressbar/dist/styles.css";
import { Link } from "react-router-dom";
import UseToast from "utils/AfToast";
import MiniSpinner from "utils/MiniSpinner";
import Spinner from "utils/Spinner";
import { object, string } from "yup";

const ContactUs = () => {
  const BASE_URL = process.env.REACT_APP_BACKEND_URL;

  const { mutate, isLoading } = useMutation(
    (data) =>
      request({
        url: "/contact/submit",
        method: "POST",
        body: values,
      }),
    {
      onSuccess: (data: any) => {
        resetForm();
        if (data.status == "SUCCESS") {
          UseToast("Message Has beed sent Successfully.", "success");
        } else {
          UseToast(data.message, "error");
        }
      },
      onError: (data: any) => {
        typeof data === "string" ? UseToast(data, "error") : UseToast(data.message, "error");
      },
    }
  );

  const { handleSubmit, handleBlur, values, handleChange, errors, setValues, setErrors, touched, dirty, setFieldValue, resetForm } =
    useFormik({
      validationSchema: object().shape({
        name: string().required("Required"),
        email: string().email("Email is not valid").required("Email is not valid"),
        mobile: string()
          .min(10, "Phone number is not valid")
          .matches(/^(\+\d{1,3}[- ]?)?\d{10}$/, "Phone number is not valid")
          .required("Phone number is not valid"),
        msg: string().required("Required"),
      }),
      initialValues: {
        name: "",
        email: "",
        mobile: "",
        msg: "",
      },
      onSubmit: async (values, { resetForm }) => {
        if (values.name == "" || values.msg == "") {
          UseToast("Please Enter Name and Message!", "warning");
          return;
        }
        mutate();
      },
    });

  return (
    <div className="my-10">
      <div className="flex sm:flex-col-reverse gap-16 pb-12 my-12">
        <div className="w-[45%] sm:w-full px-5 flex flex-col justify-between pt-10 pb-8">
          <div>
            <h1 className="text-4xl text-primary font-medium">Contact us</h1>

            <div className="flex items-center gap-2 mt-8">
              <svg width="24" height="24" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M26.9165 32.4587H11.0832C6.33317 32.4587 3.1665 30.0837 3.1665 24.542V13.4587C3.1665 7.91699 6.33317 5.54199 11.0832 5.54199H26.9165C31.6665 5.54199 34.8332 7.91699 34.8332 13.4587V24.542C34.8332 30.0837 31.6665 32.4587 26.9165 32.4587Z"
                  fill="#3F2E13"
                  stroke="#3F2E13"
                  stroke-width="2.375"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M26.9168 14.25L21.961 18.2083C20.3302 19.5067 17.6543 19.5067 16.0235 18.2083L11.0835 14.25"
                  stroke="white"
                  stroke-width="2.375"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <span className="text-sm">newsaudible@newsaudible.in</span>
            </div>

            <div className="flex items-center gap-2 mt-6">
              <svg width="24" height="24" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M34.7857 29.0228C34.7857 29.5928 34.659 30.1787 34.3898 30.7487C34.1207 31.3187 33.7723 31.857 33.3132 32.3637C32.5373 33.2187 31.6823 33.8362 30.7165 34.232C29.7665 34.6278 28.7373 34.8337 27.629 34.8337C26.014 34.8337 24.2882 34.4537 22.4673 33.6778C20.6465 32.902 18.8257 31.857 17.0207 30.5428C15.1998 29.2128 13.474 27.7403 11.8273 26.1095C10.1965 24.4628 8.724 22.737 7.40984 20.932C6.1115 19.127 5.0665 17.322 4.3065 15.5328C3.5465 13.7278 3.1665 12.002 3.1665 10.3553C3.1665 9.27866 3.3565 8.24949 3.7365 7.29949C4.1165 6.33366 4.71817 5.44699 5.55734 4.65533C6.57067 3.65783 7.679 3.16699 8.85067 3.16699C9.294 3.16699 9.73734 3.26199 10.1332 3.45199C10.5448 3.64199 10.909 3.92699 11.194 4.33866L14.8673 9.51616C15.1523 9.91199 15.3582 10.2762 15.5007 10.6245C15.6432 10.957 15.7223 11.2895 15.7223 11.5903C15.7223 11.9703 15.6115 12.3503 15.3898 12.7145C15.184 13.0787 14.8832 13.4587 14.5032 13.8387L13.2998 15.0895C13.1257 15.2637 13.0465 15.4695 13.0465 15.7228C13.0465 15.8495 13.0623 15.9603 13.094 16.087C13.1415 16.2137 13.189 16.3087 13.2207 16.4037C13.5057 16.9262 13.9965 17.607 14.6932 18.4303C15.4057 19.2537 16.1657 20.0928 16.989 20.932C17.844 21.7712 18.6673 22.547 19.5065 23.2595C20.3298 23.9562 21.0107 24.4312 21.549 24.7162C21.6282 24.7478 21.7232 24.7953 21.834 24.8428C21.9607 24.8903 22.0873 24.9062 22.2298 24.9062C22.499 24.9062 22.7048 24.8112 22.879 24.637L24.0823 23.4495C24.4782 23.0537 24.8582 22.7528 25.2223 22.5628C25.5865 22.3412 25.9507 22.2303 26.3465 22.2303C26.6473 22.2303 26.964 22.2937 27.3123 22.4362C27.6607 22.5787 28.0248 22.7845 28.4207 23.0537L33.6615 26.7745C34.0732 27.0595 34.3582 27.392 34.5323 27.7878C34.6907 28.1837 34.7857 28.5795 34.7857 29.0228Z"
                  fill="#3F2E13"
                  stroke="#3F2E13"
                  stroke-width="2.375"
                  stroke-miterlimit="10"
                />
                <path
                  d="M17.0206 30.5427C18.8256 31.8568 20.6464 32.9018 22.4672 33.6777C24.2881 34.4535 26.0139 34.8335 27.6289 34.8335C28.7372 34.8335 29.7664 34.6277 30.7164 34.2318C31.6822 33.836 32.5372 33.2185 33.3131 32.3635C33.7722 31.8568 34.1206 31.3185 34.3897 30.7485C34.6589 30.1785 34.7856 29.5927 34.7856 29.0227C34.7856 28.5793 34.6906 28.1835 34.5322 27.7877C34.3581 27.3918 34.0731 27.0593 33.6614 26.7743L28.4206 23.0535C28.0247 22.7843 27.6606 22.5785 27.3122 22.436C26.9639 22.2935 26.6472 22.2302 26.3464 22.2302C25.9506 22.2302 25.5864 22.341 25.2222 22.5627C24.8581 22.7527 24.4781 23.0535 24.0822 23.4493L22.8789 24.6368C22.7047 24.811 22.4989 24.906 22.2297 24.906C22.0872 24.906 21.9606 24.8902 21.8339 24.8427C21.7231 24.7952 21.6281 24.7477 21.5489 24.716C21.0106 24.431 20.3297 23.956 19.5064 23.2593C18.6672 22.5468 17.8439 21.771 16.9889 20.9318C16.1656 20.0927 15.4056 19.2535 14.6931 18.4302C13.9964 17.6068 13.5056 16.926 13.2206 16.4035C13.1889 16.3085 13.1414 16.2135 13.0939 16.0868C13.0622 15.9602 13.0464 15.8493 13.0464 15.7227"
                  stroke="#3F2E13"
                  stroke-width="2.375"
                  stroke-miterlimit="10"
                />
              </svg>

              <span className="text-sm">+91 78029 44797</span>
            </div>

            <div className="flex items-center gap-2 mt-6">
              <a href={"https://x.com/newsaudibleME?t=L6LmtTpUC5TAPDhElDa_RQ&s=09"} target="_blank">
                <Img src="/x.png" className="h-10" />
              </a>
              <a href={"https://www.facebook.com/profile.php?id=61555961889974&mibextid=ZbWKwL"} target="_blank">
                <Img src="/fb.png" className="h-10" />
              </a>
              <a href={"https://www.instagram.com/newsaudible?igsh=cXRiZTdvbzBmdmc3"} target="_blank">
                <Img src="/ig.png" className="h-10" />
              </a>
              <a href={"https://www.linkedin.com/in/newsaudible-media-486a4b243"} target="_blank">
                <Img src="/linkedin.png" className="h-10" />
              </a>
            </div>
          </div>

          <div className="mt-6">
            <p className="flex gap-2 items-start">
              <svg className="h-6 w-8 mt-1" width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M22.0325 1.70234C22.2928 0.706117 23.7072 0.706116 23.9675 1.70234L28.0315 17.2538C28.1229 17.6038 28.3962 17.8771 28.7462 17.9685L44.2977 22.0325C45.2939 22.2928 45.2939 23.7072 44.2977 23.9675L28.7462 28.0315C28.3962 28.1229 28.1229 28.3962 28.0315 28.7462L23.9675 44.2977C23.7072 45.2939 22.2928 45.2939 22.0325 44.2977L17.9685 28.7462C17.8771 28.3962 17.6038 28.1229 17.2538 28.0315L1.70234 23.9675C0.706117 23.7072 0.706116 22.2928 1.70234 22.0325L17.2538 17.9685C17.6038 17.8771 17.8771 17.6038 17.9685 17.2538L22.0325 1.70234Z"
                  fill="#EFBD22"
                />
              </svg>
              <span className=" font-medium text-md">
                Our team will contact you soon. Till you can explore some{" "}
                <Link to={"/"}>
                  <span className="underline text-[#6A79FF]">knowledge</span>
                </Link>
              </span>
            </p>
          </div>
        </div>
        <div className="w-[55%] sm:w-full">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit(e);
            }}
          >
            <div className="bg-[#FFFCF1] border border-[#D9D3C5] rounded-xl overflow-hidden p-8 relative">
              <h3 className="text-2xl text-primary font-medium mb-6">Get in touch</h3>

              <div className={`border border-[#D9D3C5] rounded-lg bg-white mt-4 ${errors.name && touched.name && "border-red-500"}`}>
                <Input
                  type="text"
                  placeholder="Your Name"
                  className="p-3 w-full"
                  name="name"
                  onChange={(e) => {
                    setFieldValue("name", e);
                  }}
                  value={values.name}
                  onBlur={handleBlur}
                />
              </div>
              {errors.name && touched.name && <p className="text-sm text-red-500">{errors.name}</p>}
              <div className={`border border-[#D9D3C5] rounded-lg bg-white mt-4 ${errors.email && touched.email && "border-red-500"}`}>
                <Input
                  type="text"
                  placeholder="Your email address"
                  className="p-3 w-full"
                  name="email"
                  onChange={(e) => {
                    setFieldValue("email", e);
                  }}
                  value={values.email}
                  onBlur={handleBlur}
                />
              </div>
              {errors.email && touched.email && <p className="text-sm text-red-500">{errors.email}</p>}
              <div className={`border border-[#D9D3C5] rounded-lg bg-white mt-4 ${errors.mobile && touched.mobile && "border-red-500"}`}>
                <Input
                  type="text"
                  placeholder="Your mobile number"
                  className="p-3 w-full"
                  name="mobile"
                  onChange={(e) => {
                    setFieldValue("mobile", e);
                  }}
                  value={values.mobile}
                  onBlur={handleBlur}
                />
              </div>
              {errors.mobile && touched.mobile && <p className="text-sm text-red-500">{errors.mobile}</p>}
              <div className={`border border-[#D9D3C5] rounded-lg bg-white mt-4 ${errors.msg && touched.msg && "border-red-500"}`}>
                <textarea
                  placeholder="Write your query"
                  className="p-3 bg-transparent border-0 w-full"
                  name="msg"
                  onChange={handleChange}
                  value={values.msg}
                  onBlur={handleBlur}
                />
              </div>
              {errors.msg && touched.msg && <p className="text-sm text-red-500">{errors.msg}</p>}
              <button disabled={isLoading} className="bg-primary text-white px-10 py-3 mt-4 rounded-md w-full" type="submit">
                {isLoading && <MiniSpinner />}
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default ContactUs;
