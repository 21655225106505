import React, { useEffect, useState } from "react";

import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

import { PostLoginResponseType, postForgot, postLogin, postLogout, resetPass } from "service/api";
import * as yup from "yup";

import { Button, Img, Input, Text } from "components";

import useForm from "hooks/useForm";

import "react-toastify/dist/ReactToastify.css";
import { useAuthStore } from "store/auth";
import UseToast from "utils/AfToast";
import Spinner from "utils/Spinner";
import { useMutation, useQuery } from "@tanstack/react-query";
import { GetCommanWay, ListCommanWay, PutCommanWay } from "API/commonApi";
import { useFormik } from "formik";

export const MyAccount: React.FC = () => {
  const { setToken, setUser, token, removeAll } = useAuthStore();
  const [login, setLogin] = React.useState<PostLoginResponseType>();
  const formValidationSchema = yup.object().shape({
    code: yup.string().required("OTP is required"),
    newPassword: yup.string().required("New Password is required"),
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [passVisible, setPassVisible] = React.useState<boolean>(false);

  const [profile, setProfile] = useState<any>({});
  const {} = useQuery(["selfProfile"], () => GetCommanWay("/admin/user/me", {}, {}), {
    keepPreviousData: true,
    onSuccess(data) {
      console.log(data);
      if (data.status == "SUCCESS") {
        setProfile(data.data);
      }
    },
    onError(err) {},
  });
  useEffect(() => {
    if (profile?.id) {
      defaultValues();
    }
  }, [profile]);

  const { handleSubmit, handleBlur, values, handleChange, errors, setTouched, touched, dirty, setFieldValue } = useFormik({
    validationSchema: yup.object().shape({}),
    initialValues: {
      firstName: "",
      lastName: "",
      gender: "",
      dob: "",
      mobile: "",
      pincode: "",
    },
    onSubmit: async (values, { resetForm }) => {
      mutate({
        url: "/admin/user/update-profile/",
        data: values,
      });
    },
  });

  const navigate = useNavigate();

  const { mutate, isLoading: isloading } = useMutation(PutCommanWay, {
    onSuccess: (data: any) => {
      //console.log(data, data.status == "SUCCESS");
      if (data.status == "SUCCESS") {
        UseToast("Data Updated Successfully", "success");
      } else {
        UseToast(data.message, "error");
      }
    },
    onError: (data: any) => {
      typeof data === "string" ? UseToast(data, "error") : UseToast(data.message, "error");
    },
  });

  const defaultValues = () => {
    setFieldValue("firstName", profile?.firstName);
    setFieldValue("lastName", profile?.lastName);
    setFieldValue("gender", profile?.gender);
    setFieldValue("dob", profile?.dob);
    setFieldValue("mobile", profile?.mobile);
    setFieldValue("pincode", profile?.pincode);

    setTouched({});
  };

  const handleRadioChange = (e) => {
    const { name, value } = e.target;
    setFieldValue(name, value);
  };

  const logout = () => {
    postLogout({ data: {}, headers: { Authorization: "Bearer " + token } })
      .then((res) => {
        UseToast("Logout Successfull", "success");
        localStorage.removeItem("newsaudible_token");
        removeAll();
        navigate("/login");
      })
      .catch((err) => {
        console.error(err);
        UseToast("Login Failed! Try Again", "error");
      });
  };

  return (
    <>
      {isLoading && <Spinner />}
      <div className="my-10 bg-gray-50 flex flex-col font-gilroy items-center justify-center mx-auto md:px-10 sm:px-5 w-full h-full">
        <div className="flex flex-col gap-[30px] items-center justify-start w-full">
          <div className=" bg-[#FFFBF5] border border-[#EFBD22] py-10 md:5 px-10 md:px-10 sm:px-4 rounded-lg relative w-full">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit(e);
              }}
            >
              <div className="flex flex-col items-center justify-start w-full ">
                <div className="text-center">
                  <h1 className="font-bold text-primary text-xl">Account details</h1>
                </div>

                <div className="grid grid-cols-2 sm:grid-cols-1 w-full gap-x-6">
                  <div className="w-full mt-6">
                    <Input
                      name="firstName"
                      placeholder="First Name"
                      className="font-medium p-0 text-base text-left w-full"
                      wrapClassName="bg-white border border-blue_gray-100 border-solid p-3 rounded-lg w-full"
                      type="text"
                      onBlur={handleBlur}
                      onChange={(e) => {
                        setFieldValue("firstName", e);
                      }}
                      errors={[errors?.firstName]}
                      value={values?.firstName}
                    ></Input>
                  </div>

                  <div className="w-full mt-6">
                    <Input
                      name="lastName"
                      placeholder="Last Name"
                      className="font-medium p-0 text-base text-left w-full"
                      wrapClassName="bg-white border border-blue_gray-100 border-solid p-3 rounded-lg w-full"
                      type="text"
                      onBlur={handleBlur}
                      onChange={(e) => {
                        setFieldValue("lastName", e);
                      }}
                      errors={[errors?.lastName]}
                      value={values?.lastName}
                    ></Input>
                  </div>

                  <div className="w-full mt-6">
                    <div className="flex justify-between bg-white border border-blue_gray-100 border-solid p-3 rounded-lg w-full">
                      <p className="text-sm font-medium mt-1">Select gender:</p>
                      <div className="flex gap-3 h-7">
                        <div className="flex items-center gap-1">
                          <input
                            type="radio"
                            name="gender"
                            value={"male"}
                            id="gender1"
                            checked={values.gender.toLowerCase() == "male"}
                            onChange={handleRadioChange}
                          />
                          <label htmlFor="gender1">Male</label>
                        </div>
                        <div className="flex items-center gap-1">
                          <input
                            type="radio"
                            name="gender"
                            value={"female"}
                            checked={values.gender.toLowerCase() == "female"}
                            onChange={handleRadioChange}
                            id="gender2"
                          />
                          <label htmlFor="gender2">Female</label>
                        </div>
                        <div className="flex items-center gap-1">
                          <input
                            type="radio"
                            name="gender"
                            value={"other"}
                            checked={values.gender.toLowerCase() == "other"}
                            onChange={handleRadioChange}
                            id="gender3"
                          />
                          <label htmlFor="gender3">Other</label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="w-full mt-6">
                    <Input
                      name="dob"
                      placeholder="Date of birth"
                      className="font-medium p-0 text-base text-left w-full"
                      wrapClassName="bg-white border border-blue_gray-100 border-solid p-3 rounded-lg w-full"
                      type="date"
                      onBlur={handleBlur}
                      onChange={(e) => {
                        setFieldValue("dob", e);
                      }}
                      errors={[errors?.dob]}
                      value={values?.dob}
                    ></Input>
                  </div>

                  <div className="w-full mt-6">
                    <Input
                      name="mobile"
                      placeholder="Mobile"
                      className="font-medium p-0 text-base text-left w-full"
                      wrapClassName="bg-white border border-blue_gray-100 border-solid p-3 rounded-lg w-full"
                      type="text"
                      onBlur={handleBlur}
                      onChange={(e) => {
                        setFieldValue("mobile", e);
                      }}
                      errors={[errors?.mobile]}
                      value={values?.mobile}
                    ></Input>
                  </div>

                  <div className="w-full mt-6">
                    <Input
                      name="pincode"
                      placeholder="Pincode"
                      className="font-medium p-0 text-base text-left w-full"
                      wrapClassName="bg-white border border-blue_gray-100 border-solid p-3 rounded-lg w-full"
                      type="text"
                      onBlur={handleBlur}
                      onChange={(e) => {
                        setFieldValue("pincode", e);
                      }}
                      errors={[errors?.pincode]}
                      value={values?.pincode}
                    ></Input>
                  </div>

                  <div className="w-full mt-6">
                    <Input
                      name="code"
                      placeholder="Enter OTP*"
                      className="font-medium p-0 text-base text-left w-full"
                      wrapClassName="bg-white border border-blue_gray-100 border-solid p-3 rounded-lg w-full"
                      type="text"
                      value={profile?.email}
                    ></Input>
                  </div>

                  <div className="w-full mt-6 flex justify-between bg-white border border-blue_gray-100 border-solid p-3 rounded-lg">
                    <Link to={"/change-password"} className="text-sm mt-0.5 text-blue-800 underline font-medium">
                      Change current password
                    </Link>
                  </div>
                </div>

                <div className="flex gap-4 items-center justify-center w-full mt-10">
                  <Button
                    className="common-pointer bg-primary text-white cursor-pointer font-medium py-[15px] rounded-md text-base text-center w-full max-w-sm"
                    type="submit"
                  >
                    Update details
                  </Button>

                  <Button
                    onClick={logout}
                    className={
                      "common-pointer bg-secondary text-primary cursor-pointer font-medium py-[15px] rounded-md text-base text-center w-full max-w-[100px]"
                    }
                  >
                    Logout
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
