import React, { useState } from "react";

import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

import { PostLoginResponseType, postForgot, postLogin, resetPass } from "service/api";
import * as yup from "yup";

import { Button, Img, Input, Text } from "components";

import useForm from "hooks/useForm";

import "react-toastify/dist/ReactToastify.css";
import { useAuthStore } from "store/auth";
import UseToast from "utils/AfToast";
import Spinner from "utils/Spinner";
import { useMutation } from "@tanstack/react-query";
import { ListCommanWay, PutCommanWay } from "API/commonApi";
import { useFormik } from "formik";

export const ChangePass: React.FC = () => {
  const { setToken, setUser, token } = useAuthStore();
  const [login, setLogin] = React.useState<PostLoginResponseType>();
  const formValidationSchema = yup.object().shape({
    code: yup.string().required("OTP is required"),
    newPassword: yup.string().required("New Password is required"),
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [passVisible, setPassVisible] = React.useState<boolean>(false);
  const [oldPassVisible, setOldPassVisible] = React.useState<boolean>(false);

  const { handleSubmit, handleBlur, values, handleChange, errors, setTouched, touched, dirty, setFieldValue } = useFormik({
    validationSchema: yup.object().shape({
      oldPassword: yup.string().required("Required Field"),
      newPassword: yup.string().required("Required Field"),
    }),
    initialValues: {
      oldPassword: "",
      newPassword: "",
    },
    onSubmit: async (values, { resetForm }) => {
      mutate({
        url: "/admin/user/change-password",
        data: {
          oldPassword: values.oldPassword,
          newPassword: values.newPassword,
        },
      });
    },
  });

  const navigate = useNavigate();

  const { mutate, isLoading: isloading } = useMutation(PutCommanWay, {
    onSuccess: (data: any) => {
      //console.log(data, data.status == "SUCCESS");
      if (data.status == "SUCCESS") {
        UseToast("Data Added Successfully", "success");
        navigate('/my-account')
      } else {
        UseToast(data.message, "error");
      }
    },
    onError: (data: any) => {
      typeof data === "string" ? UseToast(data, "error") : UseToast(data.message, "error");
    },
  });

  return (
    <>
      {isLoading && <Spinner />}
      <div className="my-10 bg-gray-50 flex flex-col font-gilroy items-center justify-center mx-auto md:px-10 sm:px-5 w-full h-full">
        <div className="flex flex-col gap-[30px] items-center justify-start max-w-xl">
          <div className=" bg-[#FFFBF5] border border-[#EFBD22] py-10 md:5 px-20 md:px-10 sm:px-4 rounded-lg relative">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit(e);
              }}
            >
              <div className="flex flex-col items-center justify-start w-full ">
                <div className="text-center mt-6">
                  <h1 className="font-bold text-primary text-xl">Reset password</h1>
                  <p className="text-[#7A7A7A] mt-2 mb-4">Enter your Old password and New Password below to continue</p>
                </div>

                <div className="w-full mt-6">
                  <Input
                    name="oldPassword"
                    placeholder="Old Password"
                    className="font-medium p-0 text-base text-left w-full"
                    wrapClassName="bg-white border border-blue_gray-100 border-solid p-3 rounded-lg w-full"
                    type={oldPassVisible ? "text" : "password"}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setFieldValue("oldPassword", e);
                    }}
                    errors={[errors?.oldPassword]}
                    value={values?.oldPassword}
                    suffix={
                      <Img
                        onClick={() => {
                          setOldPassVisible(!oldPassVisible);
                        }}
                        className="cursor-pointer h-5 ml-[35px] mr-3"
                        src="images/img_eye.svg"
                        alt="eye"
                      />
                    }
                  ></Input>
                </div>
                <div className="w-full mt-6">
                  <Input
                    name="newPassword"
                    placeholder="Enter New Password*"
                    className="font-medium p-0 text-base text-left w-full"
                    wrapClassName="bg-white border border-blue_gray-100 border-solid p-3 rounded-lg w-full"
                    type={passVisible ? "text" : "password"}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setFieldValue("newPassword", e);
                    }}
                    errors={[errors?.newPassword]}
                    value={values?.newPassword}
                    suffix={
                      <Img
                        onClick={() => {
                          setPassVisible(!passVisible);
                        }}
                        className="cursor-pointer h-5 ml-[35px] mr-3"
                        src="images/img_eye.svg"
                        alt="eye"
                      />
                    }
                  ></Input>
                </div>

                <div className="flex flex-col gap-4 items-center justify-start w-full my-10">
                  <Button
                    className="common-pointer bg-primary text-white cursor-pointer font-medium py-[15px] rounded-md text-base text-center w-full"
                    type="submit"
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
