import React from "react";

import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

import { PostLoginResponseType, postLogin } from "service/api";
import * as yup from "yup";

import { Button, Img, Input, Text } from "components";

import useForm from "hooks/useForm";

import "react-toastify/dist/ReactToastify.css";
import { useAuthStore } from "store/auth";
import UseToast from "utils/AfToast";

export const Login: React.FC = () => {
  const { setToken, setUser, token } = useAuthStore();
  const [login, setLogin] = React.useState<PostLoginResponseType>();
  const formValidationSchema = yup.object().shape({
    username: yup.string().required("Username is required"),
    password: yup.string().required("Password is required"),
  });

  const [passVisible, setPassVisible] = React.useState<boolean>(false);

  const form = useForm(
    { username: "", password: "" },
    {
      validate: true,
      validateSchema: formValidationSchema,
      validationOnChange: true,
    }
  );
  const navigate = useNavigate();

  function login1(data: Partial<{ username: string; password: string }>) {
    const req = {
      data: { username: data?.username, password: data?.password },
    };

    postLogin(req)
      .then((res) => {
        UseToast("Login successful.", "success");
        setLogin(res?.data);

        setToken(res?.data?.data?.token);
        setUser(res?.data?.data);

        localStorage.setItem("newsaudible_token", JSON.stringify(res?.data?.data?.token));

        navigate("/");
      })
      .catch((err) => {
        console.error(err);
        UseToast("Login Failed! Try Again", "error");
      });
  }

  return (
    <>
      <div className="bg-gray-50 flex flex-col font-gilroy items-center justify-center mx-auto md:px-10 sm:px-5 w-full h-full">
        <div className="flex flex-col gap-[30px] items-center justify-start max-w-xl">
          <div className=" bg-[#FFFBF5] border border-[#EFBD22] py-10 md:5 px-20 md:px-10 sm:px-4 rounded-lg relative">
            <div className="text-right">
              <Button
                onClick={() => {
                  navigate("/");
                }}
                className="bg-primary text-sm text-white rounded-md px-2 py-1 absolute top-2 right-2"
              >
                Back to Home
              </Button>
            </div>
            <div className="flex flex-col items-center justify-start w-full ">
              <div className="flex items-center gap-4">
                <Img src="/logo192.png" className="h-10" />
                <Img src={"/logo.png"} style={{ mixBlendMode: "difference" }} width={150} />
              </div>

              <div className="text-center mt-6">
                <h1 className="font-bold text-primary text-xl">Login</h1>
                <p className="text-[#7A7A7A] mt-2 mb-4">Enter your credential to continue with your account</p>
              </div>

              <div className="w-full mt-6">
                <Input
                  name="email"
                  placeholder="Enter your Email"
                  className="font-medium p-0 text-base text-left w-full"
                  wrapClassName="bg-white border border-blue_gray-100 border-solid p-3 rounded-lg w-full"
                  type="text"
                  onChange={(e) => {
                    form.handleChange("username", e);
                  }}
                  errors={form?.errors?.username}
                  value={form?.values?.username}
                ></Input>
              </div>

              <div className="w-full mt-6">
                <Input
                  name="InputField"
                  placeholder="Enter Password"
                  className="font-medium p-0 text-base text-left w-full"
                  wrapClassName="bg-white border border-blue_gray-100 border-solid flex pl-3 py-3 rounded-lg sm:w-full"
                  type={passVisible ? "text" : "password"}
                  onChange={(e) => {
                    form.handleChange("password", e);
                  }}
                  errors={form?.errors?.password}
                  value={form?.values?.password}
                  suffix={
                    <Img
                      onClick={() => {
                        setPassVisible(!passVisible);
                      }}
                      className="cursor-pointer h-5 ml-[35px] mr-3"
                      src="images/img_eye.svg"
                      alt="eye"
                    />
                  }
                ></Input>
              </div>

              <div className="text-right w-full">
                <Link to={"/forgot-password"}>
                  <p className="text-[#868686] text-right font-medium mt-4 text-sm border-b-2 border-[#868686] inline-block">
                    Forgot Password?
                  </p>
                </Link>
              </div>

              <div className="flex flex-col gap-4 items-center justify-start w-full my-10">
                <Button
                  className="common-pointer bg-primary text-white cursor-pointer font-medium py-[15px] rounded-md text-base text-center w-full"
                  onClick={() => {
                    form.handleSubmit(login1);
                  }}
                >
                  Log in
                </Button>
              </div>

              <div className="border-t border-[#A3A3A3] pt-8">
                <span className="text-sm">
                  Don't have an account?{" "}
                  <Link to={"/signup"}>
                    <span className="underline text-[#3B37FF]">Register</span>
                  </Link>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
