import React from "react";
import { ErrorMessage } from "../../components/ErrorMessage";

export type InputProps = Omit<
  React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
  "size" | "prefix" | "type" | "onChange"
> &
  Partial<{
    wrapClassName: string;
    className: string;
    name: string;
    placeholder: string;
    type: string;
    errors: string[];
    label: string;
    prefix: React.ReactNode;
    suffix: React.ReactNode;
    onChange: Function;
    shape: React.ReactNode;
    size: React.ReactNode;
    variant: React.ReactNode;
  }>;

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      wrapClassName = "",
      className = "",
      name = "",
      placeholder = "",
      type = "text",
      children,
      errors = [],
      label = "",
      prefix,
      suffix,
      onChange,
      shape = "",
      size = "",
      variant = "",
      color = "",
      ...restProps
    },
    ref
  ) => {
    const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
      if (onChange) onChange(e?.target?.value);
    };

    return (
      <>
        <div
          className={`${wrapClassName} 
               
               
              `}
        >
          {!!label && label}
          <div className="flex w-full items-center">
            {!!prefix && prefix}
            <input
              ref={ref}
              className={`${className} bg-transparent ${type != "radio" ? "border-0" : ""}`}
              type={type}
              name={name}
              placeholder={placeholder}
              onChange={handleChange}
              {...restProps}
            />
            {!!suffix && suffix}
          </div>
          {!!errors && <ErrorMessage errors={errors} />}
        </div>
      </>
    );
  }
);

export { Input };
