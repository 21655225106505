import { Img } from "components";
import React, { useCallback, useEffect, useState } from "react";
import "react-circular-progressbar/dist/styles.css";

const AboutUs = () => {
  const BASE_URL = process.env.REACT_APP_BACKEND_URL;

  const points = [
    {
      title: "Listen",
      data: "Content provided by Newsaudible®️ is in audio form. Listen to the required content and /or content of your interest, while eating or travelling, et el. Consume it on the go and save your time.",
    },
    {
      title: "Diverse Content",
      data: "From history to science, technology to literature, and everything in between, we cover a wide spectrum of subjects to cater to varied interests and requirements.",
    },
    {
      title: "Reliable Information",
      data: "Our team of experts meticulously researches and curates each piece of content to ensure accuracy, reliability, and relevance.",
    },
    {
      title: "Engaging Presentation",
      data: "We believe that learning should be enjoyable. That's why we strive to present information in an engaging and accessible manner, making even complex topics easy to understand.",
    },
    {
      title: "Constantly Evolving",
      data: "The world is constantly changing, and so is our content. We're committed to staying up-to-date with the latest developments and trends to provide you with fresh and relevant information.",
    },
  ];

  const team = [
    {
      name: "CS Anuradha Baranwal",
      role: "Advisor",
      img: "/CS-Anuradha-Baranwal.png",
    },
    {
      name: "Dr Umesh Pathak",
      role: "Advisor",
      img: "/Dr-Umesh-Pathak.png",
    },
    {
      name: "CA Ashish Upadhyay",
      role: "Advisor",
      img: "/CA-Ashish-Upadhyay.png",
    },
    {
      name: "Advocate Amit Patel",
      role: "Advisor",
      img: "/Advocate-Amit-Patel.png",
    },
    { name: "Jaladhi-Chhaya-Advisor", role: "Advisor", img: "/Jaladhi-Chhaya-Advisor.png" },
  ];
  return (
    <div className="my-10">
      <h1 className="text-4xl text-primary font-medium hidden sm:block">About Newsaudible</h1>

      <div className="flex sm:flex-col-reverse gap-16 border-b border-[#C6C6C6] pb-12 my-12">
        <div className="w-[55%] sm:w-full">
          <h1 className="text-4xl text-primary font-medium sm:hidden">About Newsaudible</h1>

          <ul className="font-medium text-xl list-disc ml-8 mt-14 sm:mt-0">
            <li>Newsaudible®️ is registered trade mark of Newsaudible Media Enterprise.</li>
            <li className="mt-4">
              At Newsaudible®️ , we're dedicated to quenching your thirst for knowledge by providing engaging and informative content on a
              vast array of topics. Whether you're a UPSC aspirant, preparing for various competitive exams, a lifelong learner, a curious
              explorer or someone who loves to delve into the depths of knowledge, you'll find something to pique your interest here. And
              yes, if your are preparing for competitive exams, Newsaudible®️ is a must for you.
            </li>
          </ul>
        </div>
        <div className="w-[45%] sm:w-full px-5 flex items-end">
          <Img src="/about.png" />
        </div>
      </div>

      <div className="border-b border-[#C6C6C6] pb-12 my-12">
        <h1 className="text-4xl text-primary font-medium">About Newsaudible</h1>

        <div>
          {points.map((p) => {
            return (
              <div className="mt-6">
                <p className="flex gap-2 items-center">
                  <svg className="w-8" width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M22.0325 1.70234C22.2928 0.706117 23.7072 0.706116 23.9675 1.70234L28.0315 17.2538C28.1229 17.6038 28.3962 17.8771 28.7462 17.9685L44.2977 22.0325C45.2939 22.2928 45.2939 23.7072 44.2977 23.9675L28.7462 28.0315C28.3962 28.1229 28.1229 28.3962 28.0315 28.7462L23.9675 44.2977C23.7072 45.2939 22.2928 45.2939 22.0325 44.2977L17.9685 28.7462C17.8771 28.3962 17.6038 28.1229 17.2538 28.0315L1.70234 23.9675C0.706117 23.7072 0.706116 22.2928 1.70234 22.0325L17.2538 17.9685C17.6038 17.8771 17.8771 17.6038 17.9685 17.2538L22.0325 1.70234Z"
                      fill="#EFBD22"
                    />
                  </svg>
                  <span className="text-[#EFBD22] font-medium text-xl">{p.title}</span>
                </p>
                <p className="font-medium ml-10">{p.data}</p>
              </div>
            );
          })}
        </div>
      </div>

      <div className=" mb-20">
        <h1 className="text-4xl text-primary font-medium">Our team</h1>

        <div className="grid grid-cols-5 lg:grid-cols-3 gap-4 md:gap-2 mt-10 sm:grid-cols-2 sm:gap-2">
          {team.map((t) => {
            return (
              <div className="bg-[#FFFCF1] border border-[#D9D3C5] rounded-3xl p-4 md:p-2 sm:p-1 overflow-hidden">
                <div className="relative overflow-hidden rounded-3xl">
                  <Img src={t.img} />
                  <div className="absolute left-0 bottom-0 w-full py-2  text-white bg-[#646464]  bg-opacity-50 backdrop-blur-md border-t border-[#A2A2A2] rounded-b-2xl px-5">
                    <p className="font-medium md:text-sm">{t.name}</p>
                    <p className="text-xs">{t.role}</p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
export default AboutUs;
