import { useMutation, useQuery } from "@tanstack/react-query";
import { GetCommanWay, ListCommanWay } from "API/commonApi";
import { Img } from "components";
import NewsListSingleBlock from "components/NewsListSingleBlock";
import { debounce } from "lodash";
import React, { useCallback, useEffect, useState } from "react";

import "react-circular-progressbar/dist/styles.css";
import { Link, useLocation, useParams } from "react-router-dom";
import UseToast from "utils/AfToast";
import Spinner from "utils/Spinner";

const Home = ({ props: any }) => {
  const BASE_URL = process.env.REACT_APP_BACKEND_URL;
  const location: any = useLocation();
  const _params = useParams();
  const [params, setParams] = useState<any>({ cat: _params.cat || "trending", subcat: _params.subcat });
  //console.log(params, _params);

  useEffect(() => {
    setParams({ cat: _params.cat || "trending", subcat: _params.subcat });
  }, [_params]);

  const [newsList, setNewsList] = useState<any>([]);

  const [perPage, setPerPage] = useState<any>(9);
  const [pageCount, setPageCount] = useState<any>(1);
  const [currentPage, setCurrentPage] = useState<any>(1);
  const [itemCount, setItemCount] = useState<any>(-1);

  useEffect(() => {
    //console.log("params chaanges");
    setNewsList([]);
    setItemCount(-1);
    //mutate({ data: { cat: params.cat, subcat: params.subcat }, page: currentPage });
  }, [params]);

  useEffect(() => {
    let lang = localStorage.getItem("newsaudible_lang");
    if (!lang) {
      lang = "HINDI";
    }
    if (itemCount != 0 && newsList.length == 0) {
      mutate({ data: { cat: params.cat, subcat: params.subcat, lang:lang }, page: 1 });
    }
  }, [newsList]);

  /*useEffect(() => {
    if (currentPage > 1) {
      console.log("currentPage change");
      mutate({ data: {cat: params.cat, subcat: params.subcat}, page: currentPage });
    }
  }, [currentPage]);*/

  const onViewMoreClick = () => {
    mutate({ data: { cat: params.cat, subcat: params.subcat }, page: currentPage + 1 });
  };

  const { mutate, isLoading: isloading } = useMutation(
    (data: any) =>
      ListCommanWay("/admin/news/list", data.data, {
        include: ["_subCategory", "_mainCategory"],
        /*sort: params.cat == "trending" ? { views: -1 } : { id: -1 },*/
        sort: {id: -1},
        paginate: perPage,
        page: data.page,
      }),
    {
      onSuccess: (data: any) => {
        //console.log(data, data.status == "SUCCESS");
        if (data.status == "SUCCESS") {
          //UseToast("Data Added Successfully", "success");
          let finalAry = [...newsList, ...data.data.data];
          //console.log([...new Map(finalAry.map((item) => [item["id"], item])).values()]);
          setNewsList([...new Map(finalAry.map((item) => [item["id"], item])).values()]);

          setCurrentPage(data.data.paginator.currentPage);
          setPageCount(data.data.paginator.pageCount);
          setItemCount(data.data.paginator.itemCount);
        } else {
          UseToast(data.message, "error");
        }
      },
      onError: (data: any) => {
        typeof data === "string" ? UseToast(data, "error") : UseToast(data.message, "error");
      },
    }
  );

  return (
    <>
      {isloading && <Spinner />}
      <h2 className="font-medium text-4xl text-primary mt-6">
        {newsList?.length > 0 && params?.subcat
          ? (newsList[0]?._subCategory?.name)
          : params.cat == "trending"
          ? "New Arrival"
          : newsList[0]?._mainCategory?.name}
      </h2>
      <div className="mt-2 mb-5 font-medium text-gray-main">
        Category / {newsList?.length > 0 && (params.cat == "trending" ? "Latest" : newsList[0]._mainCategory.name)}{" "}
        {newsList?.length > 0 && params.subcat && " / " + newsList[0]._subCategory?.name}
      </div>

      <div className="grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-x-2 gap-y-6">
        {newsList?.map((n) => {
          return <NewsListSingleBlock props={n} key={n.id + "single"} />;
        })}
      </div>
      {!isloading && newsList?.length == 0 && (
        <div>
          <p className="font-medium text-center py-10">Coming Soon</p>
        </div>
      )}

      <div className="my-5 text-center">
        {currentPage < pageCount && (
          <button className="bg-primary text-white font-medium py-3 px-8 rounded-full text-sm" onClick={onViewMoreClick}>
            View More
          </button>
        )}
      </div>
    </>
  );
};
export default Home;
