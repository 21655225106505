import React from "react";

import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

import { postSignup } from "service/api";
import * as yup from "yup";

import { Button, Img, Input, Text } from "components";

import useForm from "hooks/useForm";

import "react-toastify/dist/ReactToastify.css";
import { useAuthStore } from "store/auth";
import UseToast from "utils/AfToast";
import { RadioGroup } from "@headlessui/react";

export const SignUp: React.FC = () => {
  const { setToken, setUser, token } = useAuthStore();
  const [login, setLogin] = React.useState<any>();
  const formValidationSchema = yup.object().shape({
    username: yup.string().required("Username is required"),
    password: yup.string().required("Password is required"),
    confirm: yup.string().required("Confirm Password is required"),
    firstName: yup.string().required("First Name is required"),
    lastName: yup.string().required("Last Name is required"),
    gender: yup.string().required("Gender is required"),
    dob: yup.string().required("DOB is required"),
    mobile: yup.string().required("Mobile is required"),
    pincode: yup.string().required("Pincode is required"),
  });

  const [passVisible, setPassVisible] = React.useState<boolean>(false);
  const [passVisible2, setPassVisible2] = React.useState<boolean>(false);
  const [page, setPage] = React.useState(1);

  const form = useForm(
    {
      username: "",
      password: "",
      confirm: "",
      firstName: "",
      lastName: "",
      gender: "male",
      dob: "",
      countryCode: "+91",
      mobile: "",
      pincode: "",
    },
    {
      validate: true,
      validateSchema: formValidationSchema,
      validationOnChange: true,
    }
  );
  const navigate = useNavigate();

  function login1(data:any) {
		console.log(data)
    const req = {
      data: { username: data?.username, password: data?.password },
    };

		console.log(data);
		data.email = data.username;
		
    postSignup({data: data})
      .then((res) => {
				UseToast("Registration Successfull", "success");
				navigate("/login");
				return;
      })
      .catch((err) => {
        console.error(err);
        UseToast("Login Failed! Try Again", "error");
      });
  }

  return (
    <>
      <div className="bg-gray-50 flex flex-col font-gilroy items-center justify-center mx-auto md:px-10 sm:px-5 w-full py-5 min-h-full">
        <div className="flex   items-center justify-center max-w-xl w-full">
          <div className=" bg-[#FFFBF5] border border-[#EFBD22] py-10 md:5 px-20 md:px-10 sm:px-4 rounded-lg w-full relative">
          <div className="text-right">
              <Button
                onClick={() => {
                  navigate("/");
                }}
                className="bg-primary text-sm text-white rounded-md px-2 py-1 absolute top-2 right-2"
              >
                Back to Home
              </Button>
            </div>
            <div className="flex flex-col items-center justify-start w-full ">
              <div className="flex items-center gap-4">
                <Img src="/logo192.png" className="h-10" />
                <Img src={"/logo.png"} style={{ mixBlendMode: "difference" }} width={150} />
              </div>

              <div className="text-center mt-6">
                <h1 className="font-bold text-primary text-xl">Register</h1>
                <p className="text-[#7A7A7A] mt-2 mb-4">Enter details below to create your account</p>
              </div>

              {page == 2 && (
                <>
                  {" "}
                  <div className="w-full mt-6">
                    <Input
                      name="email"
                      placeholder="Enter your Email"
                      className="font-medium p-0 text-base text-left w-full"
                      wrapClassName="bg-white border border-blue_gray-100 border-solid p-3 rounded-lg w-full"
                      type="text"
                      onChange={(e) => {
                        form.handleChange("username", e);
                      }}
                      errors={form?.errors?.username}
                      value={form?.values?.username}
                    ></Input>
                  </div>
                  <div className="w-full mt-6">
                    <Input
                      name="password"
                      placeholder="Create your password"
                      className="font-medium p-0 text-base text-left w-full"
                      wrapClassName="bg-white border border-blue_gray-100 border-solid pl-3 py-3 rounded-lg sm:w-full"
                      type={passVisible ? "text" : "password"}
                      onChange={(e) => {
                        form.handleChange("password", e);
                      }}
                      errors={form?.errors?.password}
                      value={form?.values?.password}
                      suffix={
                        <Img
                          onClick={() => {
                            setPassVisible(!passVisible);
                          }}
                          className="cursor-pointer h-5 ml-[35px] mr-3"
                          src="images/img_eye.svg"
                          alt="eye"
                        />
                      }
                    ></Input>
                  </div>
                  <div className="w-full mt-6">
                    <Input
                      name="confirm"
                      placeholder="Confirm your password"
                      className="font-medium p-0 text-base text-left w-full"
                      wrapClassName="bg-white border border-blue_gray-100 border-solid pl-3 py-3 rounded-lg sm:w-full"
                      type={passVisible2 ? "text" : "password"}
                      onChange={(e) => {
                        form.handleChange("confirm", e);
                      }}
                      errors={form?.errors?.confirm}
                      value={form?.values?.confirm}
                      suffix={
                        <Img
                          onClick={() => {
                            setPassVisible2(!passVisible2);
                          }}
                          className="cursor-pointer h-5 ml-[35px] mr-3"
                          src="images/img_eye.svg"
                          alt="eye"
                        />
                      }
                    ></Input>
                  </div>
                </>
              )}

              {page == 1 && (
                <>
                  <div className="w-full mt-6 flex gap-6">
                    <Input
                      name="firstName"
                      placeholder="Enter first name"
                      className="font-medium p-0 text-base text-left w-full"
                      wrapClassName="bg-white border border-blue_gray-100 border-solid p-3 rounded-lg w-full"
                      type="text"
                      onChange={(e) => {
                        form.handleChange("firstName", e);
                      }}
                      errors={form?.errors?.firstName}
                      value={form?.values?.firstName}
                    ></Input>
                    <Input
                      name="lastName"
                      placeholder="Enter last name"
                      className="font-medium p-0 text-base text-left w-full"
                      wrapClassName="bg-white border border-blue_gray-100 border-solid p-3 rounded-lg w-full"
                      type="text"
                      onChange={(e) => {
                        form.handleChange("lastName", e);
                      }}
                      errors={form?.errors?.lastName}
                      value={form?.values?.lastName}
                    ></Input>
                  </div>

                  <div className="w-full mt-6 flex sm:block gap-3 bg-white p-3 rounded-lg border border-blue_gray-100 justify-between">
                    <p className="font-medium text-primary sm:mb-3">Select gender:</p>
                    <div className="flex items-center gap-3">
                      <label className="flex items-center gap-2 text-primary font-medium text-sm">
                        <Input
                          name="gender"
                          wrapClassName="mb-0.5"
                          type="radio"
                          onChange={(e) => {
                            form.handleChange("gender", e);
                          }}
                          errors={form?.errors?.gender}
                          value={"male"}
													checked={form?.values?.gender == "male"}
                        ></Input>
                        Male
                      </label>
                      <label className="flex items-center gap-2 text-primary font-medium text-sm">
                        <Input
                          name="gender"
                          wrapClassName="mb-0.5"
                          type="radio"
                          onChange={(e) => {
                            form.handleChange("gender", e);
                          }}
                          errors={form?.errors?.gender}
                          value={"female"}
													checked={form?.values?.gender == "female"}
                        ></Input>
                        Female
                      </label>
                      <label className="flex items-center gap-2 text-primary font-medium text-sm">
                        <Input
                          name="gender"
                          wrapClassName="mb-0.5"
                          type="radio"
                          onChange={(e) => {
                            form.handleChange("gender", e);
                          }}
                          errors={form?.errors?.gender}
                          value={"other"}
													checked={form?.values?.gender == "other"}
                        ></Input>
                        Other
                      </label>
                    </div>
                  </div>

                  <div className="w-full mt-6 flex gap-6 items-center">
                    <label className="font-medium">DoB:</label>
                    <Input
                      name="dob"
                      className="font-medium p-0 text-base text-left w-full"
                      wrapClassName="bg-white border border-blue_gray-100 border-solid p-3 rounded-lg w-full"
                      type="date"
                      onChange={(e) => {
                        form.handleChange("dob", e);
                      }}
                      errors={form?.errors?.dob}
                      value={form?.values?.dob}
                    ></Input>
                  </div>

                  <div className="w-full mt-6 flex gap-6">
                    <Input
                      name="mobile"
                      placeholder="Enter Mobile number"
                      className="font-medium p-0 text-base text-left w-full"
                      wrapClassName="bg-white border border-blue_gray-100 border-solid p-3 rounded-lg w-full"
                      type="text"
                      onChange={(e) => {
                        form.handleChange("mobile", e);
                      }}
                      errors={form?.errors?.mobile}
                      value={form?.values?.mobile}
                      prefix={<div className="pr-2 border-r mr-2 border-blue_gray-100">{form?.values?.countryCode}</div>}
                    ></Input>
                  </div>

                  <div className="w-full mt-6 flex gap-6">
                    <Input
                      name="pincode"
                      placeholder="Enter pincode"
                      className="font-medium p-0 text-base text-left w-full"
                      wrapClassName="bg-white border border-blue_gray-100 border-solid p-3 rounded-lg w-full"
                      type="text"
                      onChange={(e) => {
                        form.handleChange("pincode", e);
                      }}
                      errors={form?.errors?.pincode}
                      value={form?.values?.pincode}
                    ></Input>
                  </div>
                </>
              )}

              {page == 1 && (
                <div className="flex flex-col gap-4 items-center justify-start w-full my-10">
                  <Button
                    className="common-pointer bg-primary text-white cursor-pointer font-medium py-[15px] rounded-md text-base text-center w-full"
                    onClick={() => {
                      setPage(2);
                    }}
                  >
                    Next
                  </Button>
                </div>
              )}

              {page == 2 && (
                <div className="flex gap-4 items-center justify-start w-full my-10">
                  <Button
                    className="common-pointer bg-light border border-[#EFBD22] cursor-pointer font-medium py-[15px] rounded-md text-base text-center w-full"
                    onClick={() => {
                      setPage(1);
                    }}
                  >
                    Back
                  </Button>
                  <Button
                    className="common-pointer bg-primary text-white cursor-pointer font-medium py-[15px] rounded-md text-base text-center w-full"
                    onClick={() => {
                      form.handleSubmit(login1);
                    }}
                  >
                    Register
                  </Button>
                </div>
              )}

              <div className="border-t border-[#A3A3A3] pt-8">
                <span className="text-sm">
                  Already have an account?{" "}
                  <Link to={"/login"}>
                    <span className="underline text-[#3B37FF]">Login</span>
                  </Link>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
