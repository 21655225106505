import { useMutation, useQuery } from "@tanstack/react-query";
import { GetCommanWay, ListCommanWay } from "API/commonApi";
import { Img } from "components";
import EpisodListSingleBlock from "components/EpisodListSingleBlock";
import NewsListSingleBlock from "components/NewsListSingleBlock";
import { debounce } from "lodash";
import React, { useCallback, useEffect, useState } from "react";

import "react-circular-progressbar/dist/styles.css";
import { Link, useLocation, useParams } from "react-router-dom";
import UseToast from "utils/AfToast";
import Spinner from "utils/Spinner";

const Season = () => {
  const BASE_URL = process.env.REACT_APP_BACKEND_URL;
  const location: any = useLocation();
  const params = useParams();

  const [newsList, setNewsList] = useState<any>([]);
  const [episods, setEpisods] = useState<any>([]);

  useEffect(() => {
    setNewsList([]);
  }, [params]);

  useEffect(() => {
    if (!isloading && newsList.length == 0) {
      console.log("ca;la");
      mutate({ data: { id: params.id } });
    }
  }, [newsList]);

  const { mutate, isLoading: isloading } = useMutation(
    (data: any) =>
      ListCommanWay("/admin/news/list", data.data, {
        include: ["_subCategory", "_mainCategory"],
      }),
    {
      onSuccess: (data: any) => {
        if (data.status == "SUCCESS") {
          setNewsList(data.data.data);
          episodsMutate({ data: { id: data.data.data[0].innerNews.split(",") } });
        } else {
          UseToast(data.message, "error");
        }
      },
      onError: (data: any) => {
        typeof data === "string" ? UseToast(data, "error") : UseToast(data.message, "error");
      },
    }
  );

  const { mutate: episodsMutate, isLoading: isloadingEpisods } = useMutation(
    (data: any) =>
      ListCommanWay("/admin/news/list", data.data, {
        include: ["_subCategory", "_mainCategory"],
      }),
    {
      onSuccess: (data: any) => {
        if (data.status == "SUCCESS") {
          setEpisods(data.data.data);
        } else {
          UseToast(data.message, "error");
        }
      },
      onError: (data: any) => {
        typeof data === "string" ? UseToast(data, "error") : UseToast(data.message, "error");
      },
    }
  );

  return (
    <>
      {(isloading || isloadingEpisods) && <Spinner />}
      <h2 className="font-medium text-4xl text-primary mt-6">{newsList?.length > 0 && newsList[0].title}</h2>
      <div className="mt-2 mb-5 font-medium text-gray-main">
        Category / {newsList?.length > 0 && newsList[0]._mainCategory.name}
        {" / "}
        {newsList?.length > 0 && newsList[0]._subCategory?.name}
      </div>

      <div className="grid grid-cols-1 gap-x-2 gap-y-4 my-10">
        {episods?.map((n) => {
          return <EpisodListSingleBlock props={n} key={n.id + "single"} />;
        })}
      </div>
    </>
  );
};
export default Season;
